
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND } from '@icepanel/app-canvas'
import { findModelObjectsInScope, Landscape, ModelObject, ModelObjectTechnology, ModelObjectType, ModelStatus, modelStatuses, Tag, Team } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import { findLastIndex } from '@/helpers/find-last'
import hexToRgb from '@/helpers/hex-to-rgb'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import { AlertModule } from '@/modules/alert/store'
import CatalogTechnologyMenu from '@/modules/catalog/components/technology/menu.vue'
import CodeLinkMenu from '@/modules/code/components/link-menu.vue'
import CodeLinksSetup from '@/modules/code/components/links-setup.vue'
import { CodeModule } from '@/modules/code/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import DescriptionEditor from '@/modules/editor/components/description-editor.vue'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import HistoryCompact from '@/modules/history/components/history-compact.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import ActionsMenu from '@/modules/model/components/actions-menu.vue'
import ObjectLinksList from '@/modules/model/components/objects/links-list/index.vue'
import ObjectEditableBy from '@/modules/model/components/objects/object-editable-by.vue'
import Status from '@/modules/model/components/status.vue'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { SocketModule } from '@/modules/socket/store'
import TagDeleteDialog from '@/modules/tag/components/delete-dialog.vue'
import TagGroupDeleteDialog from '@/modules/tag/components/group/delete-dialog.vue'
import TagChip from '@/modules/tag/components/tag.vue'
import TagPicker from '@/modules/tag/components/tag-picker/index.vue'
import * as tagIcons from '@/modules/tag/helpers/icons'
import { TagModule } from '@/modules/tag/store'
import TeamPicker from '@/modules/team/components/picker.vue'
import { TeamModule } from '@/modules/team/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import ConnectionDeleteDialog from '../components/connections/connection-delete-dialog.vue'
import FilterMenu from '../components/filter-menu.vue'
import InDiagrams from '../components/in-diagrams.vue'
import InFlows from '../components/in-flows.vue'
import ObjectDependenciesList from '../components/object-dependencies-list/index.vue'
import ObjectGroups from '../components/object-group/index.vue'
import ObjectCaption from '../components/objects/object-caption.vue'
import ObjectDeleteDialog from '../components/objects/object-delete-dialog.vue'
import ObjectExternal from '../components/objects/object-external.vue'
import ObjectName from '../components/objects/object-name.vue'
import ObjectParent from '../components/objects/object-parent.vue'
import ObjectParentUpdateDialog from '../components/objects/object-parent-update-dialog.vue'
import ObjectPreviewList from '../components/objects/object-preview-list.vue'
import ObjectType from '../components/objects/object-type.vue'
import ObjectTypeUpdateDialog from '../components/objects/object-type-update-dialog.vue'
import TechnologyList from '../components/technology-list/index.vue'
import * as analytics from '../helpers/analytics'
import { detailFilters, diagramFilters, externalFilters, IFilterType, typeFilters } from '../helpers/filters'
import statusIcons from '../helpers/status-icon'
import { ModelModule } from '../store'

const EDITING_INTERVAL = 10 * 1000

interface IFilter {
  diagram?: IFilterType
  external?: IFilterType
  id: string
  status?: ModelStatus
  tag?: Tag
  team?: Team
  technology?: ModelObjectTechnology
  type?: IFilterType
  detail?: IFilterType
}

interface IObjectItem extends ModelObject {
  actions: boolean
  appCount: number
  componentCount: number
  connections: number
  disabled: boolean
  domainName: string
  expanded: boolean
  filtered: IFilter[]
  inDiagrams: number
  inFlows: number
  parentHandleId: string | null
  storeCount: number
  visible: boolean
}

@Component({
  components: {
    ActionsMenu,
    Animation,
    CatalogTechnologyMenu,
    CodeLinkMenu,
    CodeLinksSetup,
    ConnectionDeleteDialog,
    DescriptionEditor,
    FilterMenu,
    HistoryCompact,
    InDiagrams,
    InFlows,
    ObjectCaption,
    ObjectDeleteDialog,
    ObjectDependenciesList,
    ObjectEditableBy,
    ObjectExternal,
    ObjectGroups,
    ObjectLinksList,
    ObjectName,
    ObjectParent,
    ObjectParentUpdateDialog,
    ObjectPreviewList,
    ObjectType,
    ObjectTypeUpdateDialog,
    OrganizationUpgradeMenu,
    Status,
    Tabs,
    TagChip,
    TagDeleteDialog,
    TagGroupDeleteDialog,
    TagPicker,
    TeamPicker,
    TechnologyList
  },
  name: 'ModelObjects'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly tableRef!: { $el: HTMLElement }
  @Ref() readonly virtualScrollRef?: { $el: HTMLElement, onScroll: () => void }
  @Ref() readonly textFieldRefs!: { $el: HTMLElement, $refs: { input: HTMLInputElement } }[]
  @Ref() readonly searchTextFieldRef!: { $el: HTMLElement, $refs: { input: HTMLInputElement } }
  @Ref() readonly modelLinksListRef?: ObjectLinksList
  @Ref() readonly codeLinkMenuRef?: CodeLinkMenu

  editing: string | null = null
  editingModel = ''

  searchModel = ''
  searchFocused = false

  activeFilterExpandedObjectHandleIds: string[] = []

  createdTime: Record<string, string> = {}
  windowWidth = 0
  scrollY = 0
  scrollYVisible = false

  editingNotificationTimer?: number

  filterChanged = false
  filterChangedTimer?: number

  keydownListener!: (e: KeyboardEvent) => void

  iconUrlForTheme = iconUrlForTheme

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get search () {
    return this.$queryValue('search') || ''
  }

  get y () {
    const y = this.$queryValue('y')
    return y ? parseInt(y) : undefined
  }

  get sort () {
    return this.$queryValue('sort')
  }

  get sortDesc () {
    return !!this.$queryValue('sort_desc')
  }

  get focus () {
    return this.$queryValue('focus')
  }

  get objectTab () {
    return this.$queryValue('object_tab')
  }

  get currentObjectHandleIds () {
    return this.$queryArray('object')
  }

  get currentFilterIds () {
    return this.$queryArray('filter')
  }

  get currentFilterIncludeIds () {
    return this.$queryArray('filter_include')
  }

  get currentFilterExcludeIds () {
    return this.$queryArray('filter_exclude')
  }

  get currentExpandedObjectHandleIds () {
    return this.$queryArray('expanded')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get sortValue () {
    return (this.headers.find(o => o.id === this.sort)?.value || null) as keyof IObjectItem | null
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscapePermission () {
    return this.currentVersionId === 'latest' ? this.landscapeModule.landscapePermission(this.currentLandscape) : 'read'
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get loading () {
    return !this.currentShareLink && (
      this.versionModule.travelling ||
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.currentShareLink || !this.teamModule.teamsListStatus.success || this.teamModule.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) ||
      (!this.modelModule.objectsSubscriptionStatus.success && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect) ||
      (!this.modelModule.connectionsSubscriptionStatus.success && !this.modelModule.connectionsSubscriptionStatus.loadingInfo.reconnect) ||
      (!this.diagramModule.diagramsSubscriptionStatus.success && !this.diagramModule.diagramsSubscriptionStatus.loadingInfo.reconnect) ||
      (!this.flowModule.flowsSubscriptionStatus.success && !this.flowModule.flowsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get currentObjects () {
    return this.currentObjectHandleIds
      .map(o => {
        const modelObjectId = this.modelModule.objectHandles[o]
        return modelObjectId ? this.modelModule.objects[modelObjectId] : undefined
      })
      .filter((o): o is ModelObject => !!o)
  }

  get currentModelTechnologies () {
    const technologies = this.currentObjects.reduce((p, c) => ({
      ...p,
      ...c.technologies
    }), {} as Record<string, ModelObjectTechnology>)
    return Object
      .values(technologies)
      .map(o => ({
        ...o,
        icon: iconUrlForTheme(o)
      }))
      .sort(sort.index)
  }

  get currentExpandedObjects () {
    return this.currentExpandedObjectHandleIds
      .map(o => {
        const modelObjectId = this.modelModule.objectHandles[o]
        return modelObjectId ? this.modelModule.objects[modelObjectId] : undefined
      })
      .filter((o): o is ModelObject => !!o)
  }

  get currentObjectIds () {
    return this.currentObjects.map(o => o.id)
  }

  get currentObject () {
    return this.currentObjects.length === 1 ? this.currentObjects[0] : undefined
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get domainsAll () {
    return !this.currentDomainHandleId && this.domains.length > 1
  }

  get currentFilter () {
    const statuses = Object.values(modelStatuses)
    return this.currentFilterIds
      .map((o): IFilter => {
        const status = statuses.find(s => s.id === o)
        const team = this.teamModule.teams.find(t => t.id === o)
        const external = externalFilters.find(e => e.id === o)
        const diagram = diagramFilters.find(d => d.id === o)
        return {
          diagram,
          external,
          id: status?.id || team?.id || external?.id || diagram?.id || '',
          status,
          team
        }
      })
      .filter(o => o.id)
  }

  get currentFilterIncludes () {
    const tags = Object.values(this.tagModule.tags)
    const technologies = this.modelModule.technologies
    return this.currentFilterIncludeIds
      .map((o): IFilter => {
        const tag = tags.find(t => t.handleId === o)
        const technology = technologies[o]
        const type = typeFilters.find(t => t.id === o)
        const detail = detailFilters.find(d => d.id === o)
        return {
          detail,
          id: tag?.id || technology?.id || type?.id || detail?.id || '',
          tag,
          technology,
          type
        }
      })
      .filter(o => o.id)
  }

  get currentFilterExcludes () {
    const statuses = Object.values(modelStatuses)
    const tags = Object.values(this.tagModule.tags)
    const technologies = this.modelModule.technologies
    return this.currentFilterExcludeIds
      .map((o): IFilter => {
        const status = statuses.find(s => s.id === o)
        const tag = tags.find(t => t.handleId === o)
        const technology = technologies[o]
        const team = this.teamModule.teams.find(t => t.id === o)
        const type = typeFilters.find(t => t.id === o)
        const external = externalFilters.find(e => e.id === o)
        const diagram = diagramFilters.find(d => d.id === o)
        const detail = detailFilters.find(d => d.id === o)
        return {
          detail,
          diagram,
          external,
          id: status?.id || tag?.id || technology?.id || team?.id || type?.id || external?.id || diagram?.id || detail?.id || '',
          status,
          tag,
          team,
          technology,
          type
        }
      })
      .filter(o => o.id)
  }

  get currentUserTyping () {
    return Object.values(this.editorModule.typing).find(o => o.id === this.currentObject?.id && o.userId !== this.userModule.user?.id)
  }

  get currentObjectLinkCount () {
    return Object.keys(this.currentObject?.links || {}).length
  }

  get organizationLimitModelObjectsReached () {
    return Object.values(this.modelModule.objects).filter(o => o.type !== 'root').length >= this.currentOrganizationLimits.modelObjects
  }

  get objectLinksSyncStatus () {
    return this.modelModule.objectLinksSyncStatus
  }

  get drawerWidth () {
    return this.currentObjects.length ? 340 : 0
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get currentObjectsByType () {
    const typeOrder: ModelObjectType[] = [
      'system',
      'actor',
      'group',
      'app',
      'store',
      'component'
    ]

    const types = this.currentObjects.reduce<Record<ModelObjectType, number>>((p, c) => ({
      ...p,
      [c.type]: p[c.type] + 1
    }), {
      actor: 0,
      app: 0,
      component: 0,
      group: 0,
      root: 0,
      store: 0,
      system: 0
    })

    return Object
      .entries(types)
      .map(([id, count]) => ({ count, id }))
      .sort((a, b) => (typeOrder as string[]).indexOf(a.id) > (typeOrder as string[]).indexOf(b.id) ? 1 : -1)
      .filter(o => o.count)
  }

  get headers () {
    if (this.domainsAll) {
      return [
        {
          id: 'name',
          text: 'Object name',
          type: 'alpha',
          value: 'name',
          width: '50%'
        },
        {
          id: 'domain',
          text: 'Domain',
          type: 'alpha',
          value: 'domainName',
          width: '15%'
        },
        {
          id: 'app_count',
          text: 'Apps',
          type: 'numeric',
          value: 'appCount',
          width: '7%'
        },
        {
          id: 'store_count',
          text: 'Stores',
          type: 'numeric',
          value: 'storeCount',
          width: '7%'
        },
        {
          id: 'component_count',
          text: 'Components',
          type: 'numeric',
          value: 'componentCount',
          width: '7%'
        },
        {
          id: 'connection_count',
          text: 'Connections',
          type: 'numeric',
          value: 'connections',
          width: '7%'
        },
        {
          id: 'diagram_count',
          text: 'In diagrams',
          type: 'numeric',
          value: 'inDiagrams',
          width: '7%'
        }
      ]
    } else {
      return [
        {
          id: 'name',
          text: 'Object name',
          type: 'alpha',
          value: 'name',
          width: '60%'
        },
        {
          id: 'app_count',
          text: 'Apps',
          type: 'numeric',
          value: 'appCount',
          width: '8%'
        },
        {
          id: 'store_count',
          text: 'Stores',
          type: 'numeric',
          value: 'storeCount',
          width: '8%'
        },
        {
          id: 'component_count',
          text: 'Components',
          type: 'numeric',
          value: 'componentCount',
          width: '8%'
        },
        {
          id: 'connection_count',
          text: 'Connections',
          type: 'numeric',
          value: 'connections',
          width: '8%'
        },
        {
          id: 'diagram_count',
          text: 'In diagrams',
          type: 'numeric',
          value: 'inDiagrams',
          width: '8%'
        }
      ]
    }
  }

  get objects () {
    const connectionCount: Record<string, number> = {}
    const appCount: Record<string, number> = {}
    const storeCount: Record<string, number> = {}
    const componentCount: Record<string, number> = {}

    Object.values(this.modelModule.connections).forEach(o => {
      if (!connectionCount[o.originId]) {
        connectionCount[o.originId] = 0
      }
      connectionCount[o.originId]++

      if (!connectionCount[o.targetId]) {
        connectionCount[o.targetId] = 0
      }
      connectionCount[o.targetId]++
    })

    const objectsArray = Object
      .values(this.modelModule.objects)
      .filter(o => o.type !== 'root' && (!o.parentId || this.modelModule.objects[o.parentId]) && (!this.currentDomain || this.currentDomain.id === o.domainId))

    objectsArray.forEach(o => {
      const parents = this.modelModule.objects[o.id]?.parentIds.map(e => this.modelModule.objects[e]).filter(o => o) ?? []
      if (o.type === 'app') {
        parents.forEach(p => {
          if (!appCount[p.id]) {
            appCount[p.id] = 0
          }
          appCount[p.id]++
        })
      } else if (o.type === 'store') {
        parents.forEach(p => {
          if (!storeCount[p.id]) {
            storeCount[p.id] = 0
          }
          storeCount[p.id]++
        })
      } else if (o.type === 'component') {
        parents.forEach(p => {
          if (!componentCount[p.id]) {
            componentCount[p.id] = 0
          }
          componentCount[p.id]++
        })
      }
    })

    return objectsArray.map((o): IObjectItem => {
      const parents = this.modelModule.objects[o.id]?.parentIds.map(e => this.modelModule.objects[e]).filter(o => o) || []

      const external = o.external || parents.some(o => o.external)

      const statusLockParent = [o, ...parents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
      const status = statusLockParent ? statusLockParent.status : o.status

      return {
        ...o,
        actions: false,
        appCount: appCount[o.id] || 0,
        componentCount: componentCount[o.id] || 0,
        connections: connectionCount[o.id] || 0,
        disabled: false,
        domainName: this.domainModule.domains[o.domainId]?.name || '',
        expanded: false,
        external,
        filtered: [],
        inDiagrams: Object.keys(o.diagrams).length,
        inFlows: Object.keys(o.flows).length,
        parentHandleId: o.parentId ? this.modelModule.objects[o.parentId].handleId : null,
        status,
        storeCount: storeCount[o.id] || 0,
        visible: false
      }
    })
  }

  get objectsSorted () {
    return this.objects.sort((a, b) => {
      const aVal = this.sortValue ? a[this.sortValue] : undefined
      const bVal = this.sortValue ? b[this.sortValue] : undefined
      if (typeof aVal === 'string' && typeof bVal === 'string' && !this.sortDesc) {
        return aVal.localeCompare(bVal) > 0 ? 1 : -1
      } else if (typeof aVal === 'string' && typeof bVal === 'string' && this.sortDesc) {
        return aVal.localeCompare(bVal) > 0 ? -1 : 1
      } else if (typeof aVal === 'number' && typeof bVal === 'number' && !this.sortDesc) {
        return aVal > bVal ? -1 : 1
      } else if (typeof aVal === 'number' && typeof bVal === 'number' && this.sortDesc) {
        return aVal > bVal ? 1 : -1
      } else if (aVal instanceof Date && bVal instanceof Date && !this.sortDesc) {
        return aVal > bVal ? -1 : 1
      } else if (aVal instanceof Date && bVal instanceof Date && this.sortDesc) {
        return aVal > bVal ? 1 : -1
      } else if (aVal && bVal) {
        return 0
      } else {
        return new Date(this.createdTime[a.id] || a.createdAt) > new Date(this.createdTime[b.id] || b.createdAt) ? -1 : 1
      }
    })
  }

  get objectHandleIds () {
    return this.objectsSorted.map(o => o.handleId)
  }

  get level1 () {
    return this.objectsSorted.filter(o => o.type === 'system' || o.type === 'group' || o.type === 'actor')
  }

  get level2 () {
    return this.objectsSorted.filter(o => o.type === 'app' || o.type === 'store')
  }

  get level3 () {
    return this.objectsSorted.filter(o => o.type === 'component')
  }

  get level2ByParent () {
    return this.level2.reduce<Record<string, IObjectItem[]>>((p, c) => ({
      ...p,
      [c.parentId || '']: [...p[c.parentId || ''] || [], c]
    }), {})
  }

  get level3ByParent () {
    return this.level3.reduce<Record<string, IObjectItem[]>>((p, c) => ({
      ...p,
      [c.parentId || '']: [...p[c.parentId || ''] || [], c]
    }), {})
  }

  get levelItems () {
    return [
      ...this.level1,
      ...this.level2,
      ...this.level3
    ]
  }

  get filteredObjectsFuzzy () {
    const fuse = new Fuse(this.levelItems, {
      keys: [
        'name',
        'icon.name',
        'caption'
      ],
      threshold: 0.3
    })
    return fuse.search(this.search).map(o => o.item)
  }

  get filteredObjects () {
    const currentFilter = this.currentFilter
    const currentFilterIncludes = this.currentFilterIncludes
    const currentFilterExcludes = this.currentFilterExcludes

    const items = this.search ? this.filteredObjectsFuzzy : this.levelItems
    return items.filter(o => {
      const statusFilters = currentFilter.map(o => o.status).filter((o): o is ModelStatus => !!o)
      const teamFilters = currentFilter.map(o => o.team).filter((o): o is Team => !!o)
      const externalFilters = currentFilter.map(o => o.external).filter((o): o is IFilterType => !!o)
      const diagramFilters = currentFilter.map(o => o.diagram).filter((o): o is IFilterType => !!o)

      const tagIncludeFilters = currentFilterIncludes.map(o => o.tag).filter((o): o is Tag => !!o)
      const technologyIncludeFilters = currentFilterIncludes.map(o => o.technology).filter((o): o is ModelObjectTechnology => !!o)
      const typeIncludeFilters = currentFilterIncludes.map(o => o.type).filter((o): o is IFilterType => !!o)
      const detailIncludeFilters = currentFilterIncludes.map(o => o.detail).filter((o): o is IFilterType => !!o)

      const tagExcludeFilters = currentFilterExcludes.map(o => o.tag).filter((o): o is Tag => !!o)
      const technologyExcludeFilters = currentFilterExcludes.map(o => o.technology).filter((o): o is ModelObjectTechnology => !!o)
      const statusExcludeFilters = currentFilterExcludes.map(o => o.status).filter((o): o is ModelStatus => !!o)
      const teamExcludeFilters = currentFilterExcludes.map(o => o.team).filter((o): o is Team => !!o)
      const externalExcludeFilters = currentFilterExcludes.map(o => o.external).filter((o): o is IFilterType => !!o)
      const typeExcludeFilters = currentFilterExcludes.map(o => o.type).filter((o): o is IFilterType => !!o)
      const diagramExcludeFilters = currentFilterExcludes.map(o => o.diagram).filter((o): o is IFilterType => !!o)
      const detailExcludeFilters = currentFilterExcludes.map(o => o.detail).filter((o): o is IFilterType => !!o)

      if (statusFilters.length && !statusFilters.every(f => `status-${o.status}` === f.id)) {
        return false
      } else if (teamFilters.length && !teamFilters.every(f => o.teamIds.includes(f.id))) {
        return false
      } else if (externalFilters.length && !externalFilters.every(f => `external-${o.external}` === f.id)) {
        return false
      } else if (diagramFilters.length && diagramFilters.some(f => f.id === 'diagram-exists') && !o.inDiagrams) {
        return false
      } else if (diagramFilters.length && diagramFilters.some(f => f.id === 'flow-exists') && !o.inFlows) {
        return false
      } else if (tagIncludeFilters.length && !tagIncludeFilters.some(f => o.tagIds.includes(f.id))) {
        return false
      } else if (technologyIncludeFilters.length && !technologyIncludeFilters.some(f => o.technologies[f.id])) {
        return false
      } else if (typeIncludeFilters.length && !typeIncludeFilters.some(f => `type-${o.type}` === f.id)) {
        return false
      } else if (detailIncludeFilters.length && !this.hasFilteredDetailInformation(detailIncludeFilters, o)) {
        return false
      } else if (tagExcludeFilters.length && tagExcludeFilters.some(f => o.tagIds.includes(f.id))) {
        return false
      } else if (technologyExcludeFilters.length && technologyExcludeFilters.some(f => o.technologies[f.id])) {
        return false
      } else if (statusExcludeFilters.length && statusExcludeFilters.some(f => `status-${o.status}` === f.id)) {
        return false
      } else if (teamExcludeFilters.length && teamExcludeFilters.some(f => o.teamIds.includes(f.id))) {
        return false
      } else if (externalExcludeFilters.length && externalExcludeFilters.some(f => `external-${o.external}` === f.id)) {
        return false
      } else if (typeExcludeFilters.length && typeExcludeFilters.some(f => `type-${o.type}` === f.id)) {
        return false
      } else if (diagramExcludeFilters.length && diagramExcludeFilters.some(f => f.id === 'diagram-exists') && o.inDiagrams) {
        return false
      } else if (diagramExcludeFilters.length && diagramExcludeFilters.some(f => f.id === 'flow-exists') && o.inFlows) {
        return false
      } else if (detailExcludeFilters.length && this.hasFilteredDetailInformation(detailExcludeFilters, o)) {
        return false
      } else {
        return true
      }
    })
  }

  get filterExpandedObjectHandleIds () {
    const handleIds = this.filteredObjects
      .map(o => {
        if (o.parentId) {
          return [o.parentId, ...this.modelModule.objects[o.parentId]?.parentIds ?? []]
            .map(e => this.modelModule.objects[e])
            .filter(m => m && m.type !== 'root')
            .map(m => m.handleId)
            .flat()
        } else {
          return []
        }
      })
      .flat()
    return [...new Set(handleIds)]
  }

  get filterActive () {
    return !!this.search || !!this.currentFilter.length || !!this.currentFilterIncludes.length || !!this.currentFilterExcludes.length
  }

  get items () {
    return this.filterActive ? this.itemFiltering : this.itemsIdle
  }

  get itemsIdle () {
    const items: IObjectItem[] = []

    const currentExpandedObjectHandleIds = this.currentExpandedObjectHandleIds
    const level2ByParent = this.level2ByParent
    const level3ByParent = this.level3ByParent

    this.level1.forEach(o => {
      const level1Expanded = currentExpandedObjectHandleIds.includes(o.handleId)

      items.push({
        ...o,
        expanded: level1Expanded
      })

      if (o.type === 'system' && level1Expanded) {
        if (this.currentLandscapePermission !== 'read' || !level2ByParent[o.id]?.length) {
          items.push({
            ...o,
            actions: true,
            expanded: level1Expanded
          })
        }

        level2ByParent[o.id]?.forEach(a => {
          const level2Expanded = currentExpandedObjectHandleIds.includes(a.handleId)

          items.push({
            ...a,
            expanded: level2Expanded
          })

          if ((a.type === 'app' || a.type === 'store') && level2Expanded) {
            if (this.currentLandscapePermission !== 'read' || !level3ByParent[a.id]?.length) {
              items.push({
                ...a,
                actions: true,
                expanded: level2Expanded
              })
            }

            level3ByParent[a.id]?.forEach(c => {
              items.push(c)
            })
          }
        })
      }
    })

    return items
  }

  get itemFiltering () {
    const items: IObjectItem[] = []

    const activeFilterExpandedObjectHandleIds = this.activeFilterExpandedObjectHandleIds
    const filterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
    const filterIncludes = this.currentFilterIncludes.filter(o => o.tag || o.technology)
    const filteredObjectIds = this.filteredObjects.map(o => o.id)
    const level2ByParent = this.level2ByParent
    const level3ByParent = this.level3ByParent

    this.level1.forEach(o => {
      const level1Expanded = activeFilterExpandedObjectHandleIds.includes(o.handleId)
      const level1Visible = filteredObjectIds.includes(o.id) || filterExpandedObjectHandleIds.includes(o.handleId)

      if (level1Visible) {
        items.push({
          ...o,
          disabled: !filteredObjectIds.includes(o.id),
          expanded: level1Expanded,
          filtered: filterIncludes.filter(f => (f.tag && o.tagIds.includes(f.tag.id)) || (f.technology && o.technologies[f.technology.id]))
        })
      }

      if (o.type === 'system' && level1Visible && level1Expanded) {
        if (level2ByParent[o.id]?.length) {
          items.push({
            ...o,
            actions: true,
            expanded: level1Expanded
          })
        }

        level2ByParent[o.id]?.forEach(a => {
          const level2Expanded = activeFilterExpandedObjectHandleIds.includes(a.handleId)
          const level2Visible = filteredObjectIds.includes(a.id) || filterExpandedObjectHandleIds.includes(a.handleId)

          if (level2Visible) {
            items.push({
              ...a,
              disabled: !filteredObjectIds.includes(a.id),
              expanded: level2Expanded,
              filtered: filterIncludes.filter(f => (f.tag && a.tagIds.includes(f.tag.id)) || (f.technology && a.technologies[f.technology.id]))
            })
          }

          if ((a.type === 'app' || a.type === 'store') && level2Visible && level2Expanded) {
            if (level3ByParent[a.id]?.length) {
              items.push({
                ...a,
                actions: true,
                expanded: level2Expanded
              })
            }

            level3ByParent[a.id]?.forEach(c => {
              if (filteredObjectIds.includes(c.id)) {
                items.push(({
                  ...c,
                  filtered: filterIncludes.filter(f => (f.tag && c.tagIds.includes(f.tag.id)) || (f.technology && c.technologies[f.technology.id]))
                }))
              }
            })
          }
        })
      }
    })

    return items
  }

  get objectTabs () {
    const tabs: ITab[] = [
      {
        id: 'details',
        text: 'Details',
        to: {
          query: this.$setQuery({
            object_tab: 'details'
          })
        }
      },
      {
        id: 'connections',
        text: 'Connections',
        to: {
          query: this.$setQuery({
            object_tab: 'connections'
          })
        }
      },
      {
        id: 'history',
        text: 'History',
        to: {
          query: this.$setQuery({
            object_tab: 'history'
          })
        }
      }
    ]
    return tabs
  }

  get drawerMode () {
    if (this.currentObjects.length > 1) {
      return 'multiple'
    } else if (this.currentObject) {
      return this.currentObject.name.includes('\n') ? 'details-two-line' : 'details-one-line'
    }
  }

  get tagColorMap () {
    return TAG_COLOR_ACTIVE
  }

  get tagColorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get tagGroupIconMap () {
    return Object
      .values(this.tagModule.tagGroups)
      .reduce<Record<string, string>>((p, c) => ({
        ...p,
        [c.id]: tagIcons.tagGroups[c.icon]
      }), {})
  }

  get statusIconMap () {
    return statusIcons
  }

  get modelStatuses () {
    const orange = hexToRgb(TAG_COLOR_ACTIVE.orange)
    return {
      deprecated: {
        backgroundColor: `rgba(${orange?.r}, ${orange?.g}, ${orange?.b}, 0.2)`,
        color: TAG_COLOR_ACTIVE[modelStatuses.deprecated.color],
        icon: statusIcons.deprecated,
        id: 'deprecated',
        title: modelStatuses.deprecated.name,
        tooltip: 'Deprecated - Available but avoid usage as this will be removed'
      },
      future: {
        color: TAG_COLOR_ACTIVE[modelStatuses.future.color],
        icon: statusIcons.future,
        id: 'future',
        title: modelStatuses.future.name,
        tooltip: 'Future - Planned implementation'
      },
      removed: {
        color: TAG_COLOR_ACTIVE[modelStatuses.removed.color],
        icon: statusIcons.removed,
        id: 'removed',
        title: modelStatuses.removed.name,
        tooltip: 'Removed - Discontinued and no longer used'
      }
    }
  }

  hasFilteredDetailInformation (filters: IFilterType[], object: ModelObject): boolean {
    return filters.some(f => {
      switch (f.id) {
        case 'has-display-description':
          return object.caption && object.caption.trim().length > 0
        case 'has-detailed-description':
          return object.description && object.description.trim().length > 0
        default:
          return false
      }
    })
  }

  @Watch('focus')
  async onFocusChanged (focus: string) {
    if (focus) {
      this.searchModel = ''

      const objectId = this.modelModule.objectHandles[focus]
      await this.$replaceQuery({
        expanded: [objectId, ...this.modelModule.objects[objectId]?.parentIds || []]
          .map(o => this.modelModule.objects[o])
          .filter(m => m.type !== 'root')
          .map(m => m.handleId),
        filter: undefined,
        filter_exclude: undefined,
        filter_include: undefined,
        focus: undefined,
        search: undefined
      })

      this.scrollToItem(focus)
    }
  }

  @Watch('items')
  async onItemsChanged () {
    await this.$nextTick()
    this.resize()
  }

  @Watch('currentExpandedObjectHandleIds')
  async onCurrentExpandedObjectHandleIdsChanged () {
    await this.$nextTick()
    this.resize()
  }

  @Watch('objectHandleIds')
  onObjectHandleIdsChanged (objectHandleIds: string, prevObjectHandleIds: string[]) {
    const removedObjectHandleIds = prevObjectHandleIds.filter(o => !objectHandleIds.includes(o))
    if (removedObjectHandleIds.length) {
      this.$replaceQuery({
        expanded: this.$removeQueryArray(...removedObjectHandleIds),
        object: this.$removeQueryArray(...removedObjectHandleIds)
      })
    }
  }

  @Watch('filterActive')
  onFilterActiveChanged (filterActive: boolean) {
    if (filterActive) {
      this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
      if (this.virtualScrollRef) {
        this.virtualScrollRef.$el.scrollTop = 0
      }
    } else {
      this.activeFilterExpandedObjectHandleIds = []
      if (this.virtualScrollRef) {
        this.virtualScrollRef.$el.scrollTop = this.y || 0
      }
    }
  }

  @Watch('currentFilterIds')
  onCurrentFilterIdsChanged (currentFilterIds: string[], prevCurrentFilterIds: string[]) {
    if (!isEqual(currentFilterIds, prevCurrentFilterIds)) {
      if (this.filterActive) {
        this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
      }

      this.filterChanged = true
      clearTimeout(this.filterChangedTimer)
      this.filterChangedTimer = window.setTimeout(() => {
        this.filterChanged = false
      }, 400)
    }
  }

  @Watch('currentFilterIncludeIds')
  onCurrentFilterIncludeIdsChanged (currentFilterIncludeIds: string[], prevCurrentFilterIncludeIds: string[]) {
    if (!isEqual(currentFilterIncludeIds, prevCurrentFilterIncludeIds)) {
      if (this.filterActive) {
        this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
      }

      this.filterChanged = true
      clearTimeout(this.filterChangedTimer)
      this.filterChangedTimer = window.setTimeout(() => {
        this.filterChanged = false
      }, 400)
    }
  }

  @Watch('currentFilterExcludeIds')
  onCurrentFilterExcludeIdsChanged (currentFilterExcludeIds: string[], prevCurrentFilterExcludeIds: string[]) {
    if (!isEqual(currentFilterExcludeIds, prevCurrentFilterExcludeIds)) {
      if (this.filterActive) {
        this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
      }

      this.filterChanged = true
      clearTimeout(this.filterChangedTimer)
      this.filterChangedTimer = window.setTimeout(() => {
        this.filterChanged = false
      }, 400)
    }
  }

  @Watch('loading')
  async onLoadingChanged (loading: boolean, prevLoading: boolean) {
    if (!loading && prevLoading) {
      await this.$nextTick()
      this.loaded()
    }
  }

  @Watch('currentLandscape')
  onCurrentLandscapeChanged (currentLandscape?: Landscape, prevCurrentLandscape?: Landscape) {
    if (currentLandscape && currentLandscape?.id !== prevCurrentLandscape?.id) {
      analytics.modelObjectsScreen.track(this, {
        landscapeId: [currentLandscape.id],
        organizationId: [currentLandscape.organizationId]
      })
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (currentOrganizationId && !this.currentShareLink && this.teamModule.teamsListStatus.loadingInfo.organizationId !== currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.teamModule.teamsList(currentOrganizationId)
    }
  }

  @Watch('filterExpandedObjectHandleIds')
  onFilterExpandedObjectHandleIdsChanged () {
    if (this.filterActive) {
      this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
    }
  }

  mounted () {
    this.keydownListener = this.keydown.bind(this)
    window.addEventListener('keydown', this.keydownListener)

    this.resize()

    if (!this.loading) {
      this.loaded()
    }

    if (this.currentOrganizationId && !this.currentShareLink && this.teamModule.teamsListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.teamModule.teamsList(this.currentOrganizationId)
    }

    if (this.currentLandscape) {
      analytics.modelObjectsScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  destroyed () {
    clearInterval(this.editingNotificationTimer)

    window.removeEventListener('keydown', this.keydownListener)

    clearTimeout(this.filterChangedTimer)

    this.updateQueryDebounce.cancel()
  }

  async loaded () {
    this.searchModel = this.search
    this.scrollY = this.y || 0

    const focusObjectHandleId = this.focus ? this.currentObjects.find(o => o.handleId === this.focus)?.handleId : undefined
    if (focusObjectHandleId) {
      this.searchModel = ''

      const objectId = this.modelModule.objectHandles[focusObjectHandleId]
      await this.$replaceQuery({
        expanded: [objectId, ...this.modelModule.objects[objectId]?.parentIds || []]
          .map(o => this.modelModule.objects[o])
          .filter(m => m.type !== 'root')
          .map(m => m.handleId),
        filter: undefined,
        filter_exclude: undefined,
        filter_include: undefined,
        focus: undefined,
        object: this.currentObjects.map(o => o.handleId),
        object_tab: this.objectTab || 'details',
        search: undefined
      })
    } else {
      await this.$replaceQuery({
        expanded: this.currentExpandedObjects.map(o => o.handleId),
        object: this.currentObjects.map(o => o.handleId),
        object_tab: this.objectTab || 'details'
      })
    }

    if (this.filterActive) {
      this.activeFilterExpandedObjectHandleIds = this.filterExpandedObjectHandleIds
    } else if (this.virtualScrollRef) {
      this.virtualScrollRef.$el.scrollTop = this.y || 0
    }

    if (focusObjectHandleId) {
      this.scrollToItem(focusObjectHandleId)
    }
  }

  updateQueryDebounce = debounce(this.updateQuery.bind(this), 500, {
    leading: false,
    trailing: true
  })

  async updateQuery () {
    const query: any = {}
    if (!this.search && this.y !== this.scrollY) {
      query.y = (Math.round(this.scrollY * 10) / 10) || undefined
    }
    if (this.search !== this.searchModel) {
      query.search = this.searchModel || undefined
      query.object = undefined
    }
    await this.$replaceQuery(query)
  }

  clickRow (item: IObjectItem, e: PointerEvent) {
    if (!item.actions && this.editing !== item.handleId) {
      if (e.metaKey) {
        this.$replaceQuery({
          object: this.$queryArray('object').includes(item.handleId) ? this.$removeQueryArray(item.handleId) : this.$unionQueryArray(item.handleId)
        })
      } else if (e.shiftKey && this.currentObjects.length) {
        const firstObjectHandleId = this.currentObjects[0].handleId
        const firstIndex = this.items.findIndex(o => o.handleId === firstObjectHandleId)
        const lastIndex = this.items.findIndex(o => o.handleId === item.handleId)
        if (firstIndex < lastIndex) {
          this.$replaceQuery({
            object: [...new Set([firstObjectHandleId, ...this.items.slice(firstIndex, lastIndex + 1).map(o => o.handleId)])]
          })
        } else {
          this.$replaceQuery({
            object: [...new Set([firstObjectHandleId, ...this.items.slice(lastIndex, firstIndex + 1).map(o => o.handleId)])]
          })
        }
      } else {
        this.saveEditing()
        this.$replaceQuery({
          object: item.handleId
        })
      }
    }
  }

  doubleClickRow (item: IObjectItem) {
    if (this.currentLandscapePermission !== 'read' && !item.actions && this.editing !== item.handleId) {
      this.startEditing(item.handleId, item.name)
    }
  }

  async toggleExpanded (handleId: string, recursive = false) {
    let objectHandleIds: string[]
    if (this.currentObjectHandleIds.includes(handleId)) {
      if (recursive) {
        objectHandleIds = this.currentObjectHandleIds
          .map(o => [
            o,
            ...Object
              .values(this.modelModule.objects)
              .filter(e => e.parentIds.includes(this.modelModule.objectHandles[o]))
              .map(o => o.handleId)
          ])
          .flat()
      } else {
        objectHandleIds = this.currentObjectHandleIds
      }
    } else {
      if (recursive) {
        objectHandleIds = [
          handleId,
          ...Object
            .values(this.modelModule.objects)
            .filter(o => o.parentIds.includes(this.modelModule.objectHandles[handleId]))
            .map(o => o.handleId)
        ]
      } else {
        objectHandleIds = [handleId]
      }
    }

    if (this.filterActive) {
      if (this.activeFilterExpandedObjectHandleIds.includes(handleId)) {
        this.activeFilterExpandedObjectHandleIds = this.activeFilterExpandedObjectHandleIds.filter(o => !objectHandleIds.includes(o))
      } else {
        this.activeFilterExpandedObjectHandleIds = [...new Set([...this.activeFilterExpandedObjectHandleIds, ...objectHandleIds])]
      }
    } else {
      if (this.currentExpandedObjectHandleIds.includes(handleId)) {
        const objectAndChildrenHandleIds = objectHandleIds
          .map(o => Object
            .values(this.modelModule.objects)
            .filter(e => e.parentIds.includes(this.modelModule.objectHandles[o]))
            .map(o => o.handleId))
          .flat()
        await this.$replaceQuery({
          expanded: this.$removeQueryArray(...objectHandleIds),
          object: this.$removeQueryArray(...objectAndChildrenHandleIds)
        })
      } else {
        await this.$replaceQuery({
          expanded: this.$unionQueryArray(...objectHandleIds)
        })
      }
    }
  }

  toggleSort (value: keyof IObjectItem) {
    if (this.sort === value) {
      if (this.sortDesc) {
        this.$replaceQuery({
          sort: undefined,
          sort_desc: undefined
        })
      } else {
        this.$replaceQuery({
          sort: value,
          sort_desc: '1'
        })
      }
    } else {
      this.$replaceQuery({
        sort: value,
        sort_desc: undefined
      })
    }
  }

  async startEditing (handleId: string, value: string) {
    this.editing = handleId
    this.editingModel = value

    await this.$nextTick()

    this.scrollToItem(handleId, false)
  }

  saveEditing (e?: FocusEvent) {
    const modelObject = this.objectsSorted.find(o => o.handleId === this.editing)
    if (modelObject) {
      const modelExists = findModelObjectsInScope(this.modelModule.objects, modelObject.id)
        .find(o => o.id !== modelObject.id && o.name.toLowerCase().trim() === this.editingModel.toLowerCase().trim())
      if (modelExists) {
        this.alertModule.resetQueuedAlerts()
        this.alertModule.pushAlert({
          color: 'error',
          message: `${this.editingModel} already exists at this level, please use a unique name`
        })
        const input = e?.target as HTMLInputElement | undefined
        input?.focus()
      } else {
        const { object, objectUpdate } = this.modelModule.generateObjectCommit(modelObject.id, {
          name: this.editingModel
        })
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscapeId,
            objectId: object.id,
            props: objectUpdate,
            versionId: this.currentVersionId
          })
        })

        this.stopEditing()
      }
    }
  }

  stopEditing () {
    this.editing = null
    this.editingModel = ''
  }

  createObject (type: ModelObjectType, parent = Object.values(this.modelModule.objects).find(o => o.type === 'root' && (!this.currentDomain || this.currentDomain.id === o.domainId))) {
    if (!parent) {
      throw new Error('Parent not found')
    }
    const { object, objectUpsert } = this.modelModule.generateObject(this.currentLandscapeId, this.currentVersionId, {
      domainId: parent.domainId,
      name: '',
      parentId: parent.id,
      type
    })
    this.modelModule.setObject(object)
    this.editorModule.addToTaskQueue({
      func: () => this.modelModule.objectUpsert({
        landscapeId: this.currentLandscapeId,
        objectId: object.id,
        props: objectUpsert,
        versionId: this.currentVersionId
      })
    })

    this.createdTime[object.id] = new Date().toISOString()

    this.startEditing(object.handleId, '')
  }

  scrollToItem (handleId: string, scrollWhenVisible = true) {
    const itemIndex = this.items.findIndex(o => o.handleId === handleId)
    const itemHeight = 32
    const itemY = itemIndex * itemHeight
    if (this.virtualScrollRef && itemIndex > -1 && (scrollWhenVisible || itemY < this.virtualScrollRef.$el.scrollTop || itemY > this.virtualScrollRef.$el.scrollTop + this.virtualScrollRef.$el.clientHeight - itemHeight)) {
      this.virtualScrollRef.$el.scrollTop = itemY
    }
  }

  editNotificationStart (id: string) {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {
          id
        }
      })
      clearInterval(this.editingNotificationTimer)
      this.editingNotificationTimer = window.setInterval(() => {
        this.editorModule.editorTypingUpdate({
          landscapeId,
          typing: {
            id
          }
        })
      }, EDITING_INTERVAL)
    }
  }

  editNotificationEnd () {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      clearInterval(this.editingNotificationTimer)
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {}
      })
    }
  }

  objectLinksSync () {
    if (this.currentObject) {
      this.modelModule.objectLinksSync({
        landscapeId: this.currentLandscapeId,
        objectId: this.currentObject.id,
        versionId: this.currentObject.versionId
      })
    }
  }

  areModelObjectsProtected (...modelObjectsIds: (string | undefined)[]): boolean {
    if (this.currentLandscapePermission === 'admin') { return false }
    return modelObjectsIds
      .filter((o): o is string => !!o)
      .some(o => {
        const modelObject = this.modelModule.objects[o]
        return (
          modelObject &&
          modelObject.teamOnlyEditing &&
          !!modelObject.teamIds.length &&
          !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
        )
      })
  }

  keydown (e: KeyboardEvent) {
    const target = e.target as HTMLElement
    const isEditable = target.isContentEditable || ['TEXTAREA', 'INPUT'].includes(target.tagName)
    if (!isEditable && !target.classList.contains('ql-editor') && window === window.parent) {
      if (this.currentObjects.length && (e.code === 'Backspace' || e.code === 'Delete') && this.currentLandscapePermission !== 'read') {
        this.$replaceQuery({
          object_delete_dialog: this.currentObjects.map(o => o.id).join(',')
        })
      } else if (e.key.toLowerCase() === 'a' && e.metaKey) {
        e.preventDefault()

        this.$replaceQuery({
          object: this.filterActive ? this.$unionQueryArray(...this.filteredObjects.map(o => o.handleId)) : this.$unionQueryArray(...this.items.filter(o => !o.actions).map(o => o.handleId))
        })
      } else if (e.code === 'Escape') {
        e.preventDefault()

        this.$replaceQuery({
          object: undefined
        })
      } else if (e.key.toLowerCase() === 'f' && e.metaKey) {
        e.preventDefault()

        this.searchTextFieldRef.$refs.input.select()
      } else if (e.key.toLowerCase() === 'arrowdown' || e.key.toLowerCase() === 'arrowup') {
        e.preventDefault()

        let objectHandleId = this.currentObject?.handleId
        if (this.currentObjectHandleIds.length > 0) {
          const firstObjectHandleId = this.currentObjectHandleIds[0]
          if (e.key.toLowerCase() === 'arrowdown') {
            const currentIndex = findLastIndex(this.items, o => o.handleId === firstObjectHandleId)
            objectHandleId = this.items[currentIndex + 1 < this.items.length ? currentIndex + 1 : 0].handleId
          } else if (e.key.toLowerCase() === 'arrowup') {
            const currentIndex = this.items.findIndex(o => o.handleId === firstObjectHandleId)
            objectHandleId = this.items[currentIndex > 0 ? currentIndex - 1 : (this.items.length - 1)].handleId
          }
        } else if (this.items.length > 0) {
          // No current object, select first
          objectHandleId = this.items[0].handleId
        }

        if (objectHandleId) {
          this.$replaceQuery({
            object: objectHandleId
          })
          this.scrollToItem(objectHandleId, false)
        }
      } else if (e.key.toLowerCase() === 'arrowright' || e.key.toLowerCase() === 'arrowleft') {
        e.preventDefault()

        if (this.currentObject) {
          this.toggleExpanded(this.currentObject.handleId)
        }
      }
    }
  }

  resize () {
    this.windowWidth = window.innerWidth
    this.scrollYVisible = this.virtualScrollRef ? this.virtualScrollRef.$el.scrollHeight > this.virtualScrollRef.$el.clientHeight : false
    this.virtualScrollRef?.onScroll()
  }
}
